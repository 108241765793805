<div class="onboarding-container py-8">
  <div class="card-responsive">
    <div class="card-body">
      <arivo-stepper #stepperComponent [showHeader]="false">
        <arivo-step>
          <app-onboarding-first-step [stepperComponent]="stepperComponent" />
        </arivo-step>

        <arivo-step>
          <app-onboarding-second-step [stepperComponent]="stepperComponent" />
        </arivo-step>

        <arivo-step>
          <app-onboarding-third-step [stepperComponent]="stepperComponent" />
        </arivo-step>
      </arivo-stepper>
    </div>
  </div>
</div>
