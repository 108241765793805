import { Component, Input, OnChanges } from '@angular/core';
import { checkLicensePlateFormat, getCountryByLPRCode } from '../../../utils/license-plate.utils';
import { LicensePlateCountry, LPRCountryCode } from '../../../models/country';

@Component({
  selector: 'arivo-license-plate',
  templateUrl: './arivo-license-plate.component.html',
  styleUrl: './arivo-license-plate.component.scss',
})
export class ArivoLicensePlateComponent implements OnChanges {
  @Input() country?: LPRCountryCode;
  @Input() licensePlate?: string;
  @Input() compact: boolean = false;
  @Input() checkInvalid: boolean = false;
  invalidWarning: boolean = false;

  ngOnChanges(): void {
    if (this.country && this.licensePlate && this.checkInvalid) {
      this.invalidWarning = !checkLicensePlateFormat(this.licensePlate, this.country);
    }

    if (this.licensePlate) {
      this.licensePlate = this.licensePlate.split(':').join(' ');
    }
  }

  get fullCountry(): LicensePlateCountry {
    return this.country ? getCountryByLPRCode(this.country) : getCountryByLPRCode('UNKNOWN');
  }
}
