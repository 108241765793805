<div class="text-center">
  <h2 class="headline-x-small mb-6">
    @if (parkingSession) {
      <span [innerText]="'Onboarding.CurrentParkingSessions' | translate"></span>
    } @else {
      <span [innerText]="'Onboarding.NoCurrentParkingSession' | translate"></span>
    }
  </h2>

  <arivo-license-plate [country]="licensePlate?.license_plate_country" [licensePlate]="licensePlate?.license_plate" [checkInvalid]="true" />
  <button class="button-primary-text mx-auto mt-2" (click)="previous()" [innerText]="'Common.Change' | translate"></button>

  @if (parkingSession) {
    <div class="well flex flex-col gap-2 mt-4 mb-6">
      <div class="flex">
        <p class="flex-grow body-medium text-neutral-50 text-left" [innerText]="'Onboarding.Start' | translate"></p>
        <p class="flex-grow body-medium text-neutral-10 text-right" [innerText]="parkingSession.entry_time | date: 'dd/MM/yy hh:mm'"></p>
      </div>

      <div class="flex">
        <p class="flex-grow body-medium text-neutral-50 text-left" [innerText]="'Onboarding.Duration' | translate"></p>
        <p
          class="flex-grow body-medium text-neutral-10 text-right"
          [innerHtml]="parkingSession.duration_in_seconds | duration: 'seconds'"
        ></p>
      </div>

      <div class="flex">
        <p class="flex-grow body-medium text-neutral-50 text-left">
          <strong [innerText]="'Onboarding.OpenAmount' | translate"></strong>
        </p>
        <p class="flex-grow body-medium text-neutral-10 text-right">
          <strong [innerText]="parkingSession.amount_unpaid.amount | localizedCurrency: parkingSession.amount_unpaid.currency"></strong>
        </p>
      </div>
    </div>
  }

  <div class="mt-4 mb-8">
    @if (!parkingSession) {
      <h3 class="headline-x-small mb-4" [innerText]="'Onboarding.IsYourLicensePlateCorrect' | translate"></h3>
      <p class="body-medium" [innerText]="'Onboarding.WeWereNotAbleToFindACurrentParkingSession' | translate"></p>
    } @else if (!parkingSession.is_cleared) {
      <p class="body-medium mb-4" [innerText]="'Onboarding.SignUpAndExitLocation' | translate"></p>
      <!--      <p class="body-medium" [innerText]="'Onboarding.OrPayNowWithoutSigningUp' | translate"></p>-->
    } @else if (parkingSession.is_cleared) {
      <h3 class="headline-x-small mb-4" [innerText]="'Onboarding.YouCanExit' | translate"></h3>
      <p class="body-medium mb-2" [innerText]="'Onboarding.YouAreAlreadyRegisteredWithThisLicensePlate' | translate"></p>
      <!--      <p class="body-medium" [innerText]="'Onboarding.YouCanAlsoPayThisParkingSessionDirectly' | translate"></p>-->
    }
  </div>

  @if (!parkingSession) {
    <arivo-step-footer [center]="true">
      <button class="button-primary" (click)="next()" [innerText]="'Onboarding.SignUpAnyway' | translate"></button>
      <button class="button-primary-outlined" [routerLink]="['/login']" [innerText]="'Common.Cancel' | translate"></button>
    </arivo-step-footer>
  } @else if (!parkingSession.is_cleared) {
    <arivo-step-footer [center]="true">
      <button class="button-primary" (click)="next()" [innerText]="'Onboarding.SignUpNowAndExit' | translate"></button>
      <button class="button-primary-outlined" [routerLink]="['/login']" [innerText]="'Common.Cancel' | translate"></button>
    </arivo-step-footer>
  } @else if (parkingSession.is_cleared) {
    <arivo-step-footer [center]="true">
      <button class="button-primary" (click)="next()" [innerText]="'Onboarding.SignUpAnyway' | translate"></button>
      <button class="button-primary-outlined" [routerLink]="['/login']" [innerText]="'Common.Cancel' | translate"></button>
    </arivo-step-footer>
  }
</div>
