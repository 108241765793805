import { Component, DestroyRef, inject, Inject, Input } from '@angular/core';
import { ArivoStepperComponent } from '../../../../shared/components/ui-components/arivo-stepper/arivo-stepper.component';
import { FormBuilder, Validators } from '@angular/forms';
import { ContractLicensePlate, LicensePlate } from '../../../../shared/models/licensePlate';
import { ContractCreateService } from '../contract-create.service';
import { Garage } from '../../../../shared/models/garage';
import { ContractTemplate } from '../../../../shared/models/contractTemplate';
import { ActivatedRoute, Router } from '@angular/router';
import { TypedRoute } from '../../../../shared/utils/router.utils';
import { ContractCreateResolve } from '../../../../shared/models/routeTyping';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../shared/services/toast.service';
import { ContractsApiService } from '../../contracts-api.service';
import { pairwise } from 'rxjs';
import { formatDateWithTimezone, parseDate } from '../../../../shared/utils/common.utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-contract-create-second-step',
  templateUrl: './contract-create-second-step.component.html',
  styleUrl: './contract-create-second-step.component.scss',
})
export class ContractCreateSecondStepComponent {
  @Input() stepperComponent!: ArivoStepperComponent;
  destroyRef = inject(DestroyRef);

  garage?: Garage;
  product?: ContractTemplate;
  licensePlates: ContractLicensePlate[] = [];

  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private toastService: ToastService,
    private translateService: TranslateService,
    private contractsApiService: ContractsApiService,
    private contractCreateService: ContractCreateService,
    @Inject(ActivatedRoute) private route: TypedRoute<ContractCreateResolve>,
  ) {
    const licensePlates: LicensePlate[] = this.route.snapshot.data.contractCreateApiResponse.license_plates;
    this.licensePlates = licensePlates.map((licensePlate: LicensePlate) => {
      return {
        ...licensePlate,
        enabled: false,
      };
    });

    this.contractCreateService.garageSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((garage) => {
      if (this.garage !== garage) {
        this.garage = garage;

        this.licensePlates = licensePlates.map((licensePlate: LicensePlate) => {
          return {
            ...licensePlate,
            enabled: false,
          };
        });

        this.contractForm.reset();
        this.contractForm.controls.licensePlates.reset();
        this.contractForm.controls.start.patchValue(new Date());
        this.contractForm.controls.parkingSpots.patchValue(1);
        this.contractForm.controls.licensePlates.patchValue([]);
      }
    });

    this.contractCreateService.productSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((product) => {
      this.product = product;
      if (this.product.initial_start) {
        this.contractForm.controls.start.patchValue(parseDate(this.product.initial_start));
      }
    });

    this.contractForm.valueChanges.pipe(pairwise(), takeUntilDestroyed(this.destroyRef)).subscribe(([oldVal, newVal]) => {
      if (oldVal.parkingSpots !== newVal.parkingSpots) {
        this.contractForm.controls.licensePlates.updateValueAndValidity();
      }
    });
  }

  get totalPrice(): number {
    const price = this.product?.price.amount;
    const vehicleQuota = this.contractForm.value.parkingSpots;
    if (this.contractForm.controls.parkingSpots.valid && price && vehicleQuota) {
      return price * vehicleQuota;
    }
    return 0;
  }

  contractForm = this._formBuilder.group({
    start: [new Date(), Validators.required],
    parkingSpots: [1, Validators.required],
    licensePlates: [<Array<LicensePlate>>[]],
    termsAndConditions: [false, [Validators.requiredTrue]],
  });

  startDatepickerFilter = (d: Date | null): boolean => {
    if (this.product?.initial_start) {
      const date = d || parseDate(this.product.initial_start);
      return parseDate(this.product.initial_start).setHours(0, 0, 0, 0) <= date.valueOf();
    } else {
      const date = d || new Date();
      return new Date().setHours(0, 0, 0, 0) <= date.valueOf();
    }
  };

  updateLicensePlates(licensePlates: LicensePlate[]) {
    this.contractForm.controls.licensePlates.patchValue(licensePlates);
    this.contractForm.controls.licensePlates.updateValueAndValidity();
  }

  finish() {
    this.contractForm.markAllAsTouched();
    this.contractForm.controls.licensePlates.updateValueAndValidity();

    const licensePlates = this.contractForm.value.licensePlates;

    if (this.contractForm.valid && this.garage && this.product) {
      const contract = {
        contract_template_id: this.product.id,
        group_limit: this.contractForm.value.parkingSpots!,
        license_plates: licensePlates!,
        start: formatDateWithTimezone(this.contractForm.value.start!),
        end: null,
      };

      this.contractsApiService
        .createContract(contract)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.router.navigateByUrl('/dashboard').finally(() => {
              this.toastService.add({
                message: this.translateService.instant('Contracts.CreateContractSuccess'),
                type: 'success',
              });
            });
          },
          error: (error) => {
            console.error(error);
            if (error.status === 400 && error.error.detail) {
              const detail = error.error.detail;
              for (const key in detail) {
                if (detail.hasOwnProperty(key)) {
                  detail[key].forEach((message: string) => {
                    this.toastService.add({
                      message: message,
                      type: 'error',
                    });
                  });
                }
              }
            } else {
              this.toastService.add({
                message: this.translateService.instant('Contracts.UpdateContractError'),
                type: 'error',
              });
            }
          },
        });
    }
  }
}
