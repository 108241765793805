import { Component, DestroyRef, Inject, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ArivoStepperComponent } from '../../../../shared/components/ui-components/arivo-stepper/arivo-stepper.component';
import { fbBoolean, fbOptionalString, fbString } from '../../../../shared/utils/form.utils';
import { licensePlateCountries } from '../../../../shared/data/countries.data';
import { User } from '../../../../shared/models/user';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../user/user.service';
import { ISO2CountryCode } from '../../../../shared/models/country';
import { ArivoPaymentService } from '../../../../shared/components/arivo-payment-container/arivo-payment.service';
import { LanguageService } from '../../../../core/services/language.service';
import { ArivoPaymentMethod } from '../../../../shared/models/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TypedRoute } from '../../../../shared/utils/router.utils';
import { OnboardingFinishResolve } from '../../../../shared/models/routeTyping';
import { ToastService } from '../../../../shared/services/toast.service';
import { ArivoDialogService } from '../../../../shared/components/arivo-dialog-container/arivo-dialog.service';
import { environment } from '../../../../../environments/environment';
import { CoreDataService } from '../../../../core/services/core-data.service';

@Component({
  selector: 'app-onboarding-finish-second-step',
  templateUrl: './onboarding-finish-second-step.component.html',
  styleUrl: './onboarding-finish-second-step.component.scss',
})
export class OnboardingFinishSecondStepComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  @Input({ required: true }) stepperComponent?: ArivoStepperComponent;

  appAndNotificationsEnabled = environment.featureFlags.enableAppAndNotifications;

  paymentMethod: ArivoPaymentMethod | null = null;
  creditCardIconClass: string | null = null;
  user: User | null = null;

  //This only reorders the list on reload, if we need to have it instantly we may have to adjust this
  selectableCountries = licensePlateCountries
    .slice()
    .sort((a, b) =>
      this.translateService.instant('CountryNames.' + a.name).localeCompare(this.translateService.instant('CountryNames.' + b.name)),
    );

  constructor(
    private _router: Router,
    private _formBuilder: FormBuilder,
    private translateService: TranslateService,
    private userService: UserService,
    private toastService: ToastService,
    private languageService: LanguageService,
    private paymentService: ArivoPaymentService,
    private dialogService: ArivoDialogService,
    private coreDataService: CoreDataService,
    @Inject(ActivatedRoute) private route: TypedRoute<OnboardingFinishResolve>,
  ) {}

  ngOnInit() {
    this.initializeUserData();
    this.setupValueChangeHandlers();
  }

  get isBusinessAccount() {
    return this.contactDetailsForm.value.business ?? false;
  }

  contactDetailsForm = this.initContactDetailsForm();

  next(): void {
    this.contactDetailsForm.markAllAsTouched();
    if (this.contactDetailsForm.valid) {
      let updatedUser: User = {
        email: this.user!.email,
        first_name: this.contactDetailsForm.value.first_name ?? null,
        last_name: this.contactDetailsForm.value.last_name ?? null,
        business: this.isBusinessAccount,
        company_name: this.contactDetailsForm.value.company_name ?? null,
        vat_id: this.contactDetailsForm.value.vat_id ?? null,
        street: this.contactDetailsForm.value.street ?? null,
        street_number: this.contactDetailsForm.value.street_number ?? null,
        zip_code: this.contactDetailsForm.value.zip_code ?? null,
        city: this.contactDetailsForm.value.city ?? null,
        country: this.contactDetailsForm.value.country ?? 'AT',
      };

      this.userService.updateUser(updatedUser).subscribe({
        next: () => {
          this.coreDataService.setCustomerAddressCompletion(true);
          if (this.appAndNotificationsEnabled) {
            this.toastService.add({ type: 'success', message: this.translateService.instant('UserData.UpdatedSuccessfully') });
            if (this.stepperComponent) {
              this.stepperComponent.next();
            }
          } else {
            this.dialogService.addDialog({
              title: this.translateService.instant('Dialog.WelcomeWiparkFinished.Title'),
              content: this.translateService.instant('Dialog.WelcomeWiparkFinished.Content'),
              dismissible: true,
              dismissButtonText: this.translateService.instant('Dialog.WelcomeWiparkFinished.Dismiss'),
              type: 'info',
            });
            this._router.navigateByUrl('/dashboard');
          }
        },
        error: (error) => {
          if (error.status === 400 && error.error?.detail) {
            this.setFormErrors(error.error.detail);
            this.toastService.add({ type: 'error', message: this.translateService.instant('UserData.UpdateFailedBadRequest') });
          } else {
            this.toastService.add({ type: 'error', message: this.translateService.instant('UserData.UpdateFailed') });
          }
        },
      });
    }
  }

  private setFormErrors(errors: { [key: string]: string[] }) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key) && this.contactDetailsForm.controls[key as keyof typeof this.contactDetailsForm.controls]) {
        const control = this.contactDetailsForm.controls[key as keyof typeof this.contactDetailsForm.controls];
        control.setErrors({ serverError: errors[key].join(' ') });
      }
    }
  }

  // Helper functions
  private initContactDetailsForm() {
    return this._formBuilder.nonNullable.group({
      first_name: [fbString, Validators.required],
      last_name: [fbString, Validators.required],
      street: [fbString, Validators.required],
      street_number: [fbString, Validators.required],
      zip_code: [fbString, Validators.required],
      city: [fbString, Validators.required],
      country: [<ISO2CountryCode>'AT', Validators.required],
      business: [fbBoolean],
      company_name: [fbOptionalString],
      vat_id: [fbOptionalString],
    });
  }

  private initializeUserData() {
    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      this.user = data.onboardingFinishApiResolve.customer;
      this.populateForms(this.user);
      this.paymentMethod = data.onboardingFinishApiResolve.payment_method ?? null;
      if (this.paymentMethod) {
        this.creditCardIconClass = this.paymentService.getCreditCardIcon(this.paymentMethod.brand);
      }
    });
    this.paymentService.activePaymentMethod.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (paymentMethod) => {
        if (paymentMethod) {
          this.paymentMethod = paymentMethod;
          this.creditCardIconClass = this.paymentService.getCreditCardIcon(this.paymentMethod.brand);
        }
      },
    });
    this.languageService.languageChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        this.selectableCountries = licensePlateCountries
          .slice()
          .sort((a, b) =>
            this.translateService.instant('CountryNames.' + a.name).localeCompare(this.translateService.instant('CountryNames.' + b.name)),
          );
      },
    });
  }

  private populateForms(user: User | null) {
    if (!user) return;
    this.populateContactDetailsForm(user);
  }

  private populateContactDetailsForm(user: User) {
    this.contactDetailsForm.controls.first_name.patchValue(user?.first_name ?? fbString);
    this.contactDetailsForm.controls.last_name.patchValue(user?.last_name ?? fbString);
    this.contactDetailsForm.controls.business.patchValue(user?.business ?? fbBoolean);
    this.contactDetailsForm.controls.company_name.patchValue(user?.company_name ?? fbOptionalString);
    this.contactDetailsForm.controls.vat_id.patchValue(user?.vat_id ?? fbOptionalString);
    this.contactDetailsForm.controls.street.patchValue(user?.street ?? fbString);
    this.contactDetailsForm.controls.street_number.patchValue(user?.street_number ?? fbString);
    this.contactDetailsForm.controls.zip_code.patchValue(user?.zip_code ?? fbString);
    this.contactDetailsForm.controls.city.patchValue(user?.city ?? fbString);
    this.contactDetailsForm.controls.country.patchValue(<ISO2CountryCode>user?.country ?? 'AT');

    this.contactDetailsForm.updateValueAndValidity();
  }

  private setupValueChangeHandlers() {
    this.contactDetailsForm.controls.business.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isBusiness) => {
      const businessControls = [
        this.contactDetailsForm.controls.company_name,
        // this.contactDetailsForm.controls.address,
        // this.contactDetailsForm.controls.zip_code,
        // this.contactDetailsForm.controls.city,
        // this.contactDetailsForm.controls.country,
      ];
      if (isBusiness) {
        for (let control of businessControls) {
          control.setValidators([Validators.required]);
          control.updateValueAndValidity();
          control.markAsUntouched();
        }
      } else {
        for (let control of businessControls) {
          control.clearValidators();
          control.updateValueAndValidity();
        }
      }
    });
  }
}
