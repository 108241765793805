@if (showParkingSessionOverview) {
  <app-onboarding-finish-transfer [parkingSession]="parkingSession" (completeProfileEvent)="showParkingSessionOverview = false" />
} @else {
  @if (parkingSession && !parkingSession.is_cleared) {
    <div class="alert mb-4">
      <div class="text-2xl icon">
        <i class="fa-light fa-triangle-exclamation"></i>
      </div>
      <span class="body-medium" [innerText]="'SignUp.ParkingSessionCouldNotBeTransferredError' | translate"></span>
    </div>
  }

  <div class="mx-auto my-2">
    <arivo-stepper #stepperComponent>
      <arivo-step [label]="'SignUp.PaymentMethod' | translate">
        <app-onboarding-finish-first-step [stepperComponent]="stepperComponent" (cancelEvent)="showParkingSessionOverview = true" />
      </arivo-step>

      <arivo-step [label]="'SignUp.ContactData' | translate">
        <app-onboarding-finish-second-step [stepperComponent]="stepperComponent" />
      </arivo-step>

      @if (appAndNotificationsEnabled) {
        <arivo-step [label]="'SignUp.AppSettings' | translate">
          <app-onboarding-finish-third-step [stepperComponent]="stepperComponent" />
        </arivo-step>
      }
    </arivo-stepper>
  </div>
}
