import { Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AdditionalDetailsActions,
  AdditionalDetailsData,
  AdyenCheckout,
  AdyenCheckoutError,
  ApplePay,
  Card,
  CoreConfiguration,
  Dropin,
  GooglePay,
  GooglePayConfiguration,
  PaymentMethod,
  PaymentMethodsResponse,
  ResultCode,
  SepaDirectDebit,
  SubmitActions,
  SubmitData,
  UIElement,
  UIElementProps,
} from '@adyen/adyen-web';
import { showSpinner } from '../../utils/common.utils';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ArivoPaymentService } from './arivo-payment.service';
import { ToastService } from '../../services/toast.service';
import { LoadingService } from '../../../core/services/loading.service';
import { ArivoDialogService, Dialog } from '../arivo-dialog-container/arivo-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../core/services/language.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'arivo-payment-container',
  templateUrl: './arivo-payment-container.component.html',
  styleUrl: './arivo-payment-container.component.scss',
})
export class ArivoPaymentContainerComponent implements OnInit {
  @Input() autoInit = false;
  @Input() remountOnError = false;
  @Output() submitted = new EventEmitter<void>();
  @Output() failed = new EventEmitter<void>();
  @Output() initialized = new EventEmitter<void>();
  @Output() setupFailed = new EventEmitter<void>();
  @ViewChild('dropInHook', { static: true }) dropInHook: ElementRef = new ElementRef('');

  // result from redirecting
  redirectResult?: string;

  private dropInComponent: any;
  private _destroyRef = inject(DestroyRef);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: ArivoPaymentService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private languageService: LanguageService,
    private dialogService: ArivoDialogService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.redirectResult = this.route.snapshot.queryParams['redirectResult'];
    if (this.redirectResult) {
      this.onAdditionalDetails({ data: { details: { redirectResult: this.redirectResult } } }, undefined, undefined, true);
      this.redirectResult = undefined;
      this.router.navigate([], { queryParams: { redirectResult: null }, replaceUrl: true });
    }
    if (this.autoInit) {
      this.setUpComponents();
    }
  }

  setUpComponents() {
    this.loadingService.setLoading(true);
    this.paymentService
      .getPaymentMethods()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: (data: { payment_methods: PaymentMethod[]; client_key: string }) => {
          this.createAdyenCheckout({ paymentMethods: data.payment_methods }, data.client_key)
            .then((checkout) => {
              this.dropInComponent = new Dropin(checkout, {
                paymentMethodComponents: [Card, GooglePay, ApplePay, SepaDirectDebit],
                paymentMethodsConfiguration: {
                  googlepay: { buttonColor: 'white', buttonType: 'subscribe' },
                  applepay: { buttonType: 'subscribe' },
                  card: { hasHolderName: environment.developmentFlags.requireCardHolderName },
                } as GooglePayConfiguration,
                instantPaymentTypes: ['applepay', 'googlepay'],
                openFirstPaymentMethod: false,
                showPayButton: true,
                onReady: () => {
                  this.loadingService.setLoading(false);
                  this.initialized.emit();
                },
              });
              this.dropInComponent.mount(this.dropInHook.nativeElement);
            })
            .catch((error) => {
              console.error(error);
              this.loadingService.setLoading(false);
              this.setupFailed.emit();
              this.toastService.showError(this.translateService.instant('Common.Errors.AdyenNotWorking'));
            });
        },
        error: (error: any) => {
          console.error(error);
          this.loadingService.setLoading(false);
          this.toastService.showError(this.translateService.instant('Common.Errors.AdyenNotWorking'));
          this.setupFailed.emit();
        },
      });
  }

  createAdyenCheckout(paymentMethods: PaymentMethodsResponse, client_key: string) {
    let configuration: CoreConfiguration = {
      paymentMethodsResponse: paymentMethods,
      clientKey: client_key,
      locale: this.languageService.getAdyenLocale(),
      countryCode: 'AT',
      environment: environment.name === 'production' ? 'live' : 'test',
      analytics: {
        enabled: false,
      },
      amount: {
        value: 0,
        currency: 'EUR',
      },
      onSubmit: this.onSubmit.bind(this),
      onAdditionalDetails: this.onAdditionalDetails.bind(this),
      onError: this.onError.bind(this),
    };
    return AdyenCheckout(configuration);
  }

  onSubmit(state: SubmitData, component: UIElement<UIElementProps>, actions: SubmitActions) {
    if (!state.isValid) {
      actions.reject();
    }

    try {
      this.paymentService
        .initiatePayment(state, this.router.url)
        .pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe((result: any) => {
          if (!result.resultCode) {
            actions.reject();
            return;
          }
          this.resolveResult(result, actions);
          if (result.action) {
            component.handleAction(result.action);
          } else {
            let successResultCodes: ResultCode[] = ['Authorised', 'Received'];
            if (successResultCodes.includes(result.resultCode)) {
              this.toastService.showSuccess(this.translateService.instant('Payment.Success'));
              this.paymentService.setActivePaymentMethod({
                type: result.paymentMethod.type,
                brand: result.paymentMethod.brand,
                card_summary: result.additionalData.cardSummary,
              });
              setTimeout(() => {
                this.submitted.emit();
              }, 2500);
            } else {
              this.onError(undefined, component);
            }
          }
        });
    } catch (error) {
      console.error(error);
      this.onError(undefined, component);
      actions.reject();
    }
  }

  onAdditionalDetails(
    state: AdditionalDetailsData,
    component?: UIElement<UIElementProps>,
    actions?: AdditionalDetailsActions,
    afterRedirect: boolean = false,
  ) {
    this.paymentService
      .submitAdditionalDetails(state)
      .pipe(showSpinner())
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: (result: any) => {
          if (!result.resultCode) {
            if (actions) {
              actions.reject();
            }
            return;
          }
          if (actions) {
            this.resolveResult(result, actions);
          }
          let successResultCodes: ResultCode[] = ['Authorised', 'Received'];
          if (successResultCodes.includes(result.resultCode)) {
            this.toastService.showSuccess(this.translateService.instant('Payment.Success'));
            this.paymentService.setActivePaymentMethod({
              type: result.paymentMethod.type,
              brand: result.paymentMethod.brand,
              card_summary: result.additionalData.cardSummary,
            });
            if (afterRedirect) {
              this.submitted.emit();
            } else {
              setTimeout(() => {
                this.submitted.emit();
              }, 2500);
            }
          } else {
            this.onError(undefined, component);
          }
        },
        error: (error: any) => {
          console.error(error);
          this.toastService.showError(this.translateService.instant('Payment.Errors.UnknownError'));
          if (actions) {
            actions.reject();
          }
        },
      });
  }

  onError(error?: AdyenCheckoutError, component?: UIElement<UIElementProps>) {
    if (error?.name !== 'CANCEL') {
      if (error) console.error(error);
      this.showStandardErrorDialog();
      if (component && this.remountOnError) {
        component.mount(this.dropInHook.nativeElement);
      }
      this.failed.emit();
    } else {
      this.toastService.showError(this.translateService.instant('Payment.Errors.Cancelled'));
    }
  }

  resolveResult(result: any, actions: SubmitActions | AdditionalDetailsActions) {
    const { resultCode, action, order, donationToken } = result;
    actions.resolve({
      resultCode,
      action,
      order,
      donationToken,
    });
  }

  unmountComponents() {
    if (this.dropInComponent) {
      setTimeout(() => {
        this.dropInComponent.closeActivePaymentMethod();
        this.dropInComponent.unmount();
      }, 150);
    }
  }

  showStandardErrorDialog() {
    const standardErrorDialog: Dialog = {
      title: this.translateService.instant('Payment.Errors.AuthorisationFailed.Title'),
      content: this.translateService.instant('Payment.Errors.AuthorisationFailed.Content'),
      dismissible: true,
      type: 'error',
      dismissButtonText: this.translateService.instant('Payment.Errors.AuthorisationFailed.Dismiss'),
    };
    this.dialogService.addDialog(standardErrorDialog);
  }
}
