import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TypedRoute } from '../../../utils/router.utils';
import { CoreDataResolve } from '../../../models/routeTyping';
import { ArivoDialogComponent } from '../../ui-components/arivo-dialog/arivo-dialog.component';
import { ArivoDialogService } from '../../arivo-dialog-container/arivo-dialog.service';
import { CoreDataService } from '../../../../core/services/core-data.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-logged-in-layout',
  templateUrl: './logged-in-layout.component.html',
  styleUrl: './logged-in-layout.component.scss',
})
export class LoggedInLayoutComponent implements OnInit {
  constructor(
    @Inject(ActivatedRoute) private route: TypedRoute<CoreDataResolve>,
    private router: Router,
    private dialogService: ArivoDialogService,
    private coreDataService: CoreDataService,
  ) {}

  @ViewChild('homeScreenDialog') homeScreenDialog!: ArivoDialogComponent;

  ngOnInit() {
    this.route.data.subscribe({
      next: (data: CoreDataResolve) => {
        this.coreDataService.setCoreData(data.core_data);

        let redirectByPaymentVerification = !!this.route.snapshot.queryParams['redirectResult'];
        const isOnboardingRoute = this.router.url.includes('/onboarding');
        const isUserDataRoute = this.router.url.includes('/data');

        this.coreDataService.warningData.pipe(take(1)).subscribe({
          next: (warningData) => {
            if (!isOnboardingRoute && !redirectByPaymentVerification) {
              if (warningData.paymentMissing && warningData.customerAddressMissing) {
                this.dialogService.showFinishRegistrationReminderDialog();
              } else if (warningData.paymentMissing) {
                this.dialogService.showPaymentMethodReminderDialog();
              } else if (warningData.customerAddressMissing && !isUserDataRoute) {
                this.dialogService.showCustomerAddressReminderDialog();
              }
            }
          },
        });
      },
    });
  }
}
