import { Injectable } from '@angular/core';
import { BehaviorSubject, pairwise } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CoreDataService } from '../../../core/services/core-data.service';

export type Dialog = {
  title: string;
  subtitle?: string;
  content: string;
  dismissible?: boolean;
  buttonText?: string;
  buttonRoute?: {
    link: string;
    queryParams?: { [key: string]: string | null };
  };
  dismissButtonText?: string;
  type: 'success' | 'info' | 'warning' | 'error';
};

@Injectable({
  providedIn: 'root',
})
export class ArivoDialogService {
  dialogues: BehaviorSubject<Dialog[]> = new BehaviorSubject<Dialog[]>([]);

  constructor(
    private translateService: TranslateService,
    private coreDataService: CoreDataService,
  ) {
    this.coreDataService.warningData.pipe(pairwise()).subscribe({
      next: ([warningDataOld, warningDataNew]) => {
        if (!warningDataOld.paymentMissing && warningDataNew.paymentMissing) {
          if (warningDataNew.customerAddressMissing) {
            this.showFinishRegistrationReminderDialog();
          } else {
            this.showPaymentMethodReminderDialog();
          }
        }
      },
    });
  }

  addDialog(dialog: Dialog): void {
    this.dialogues.next([...this.dialogues.value, dialog]);
  }

  removeDialog(index: number): void {
    const dialogs = this.dialogues.value;
    dialogs.splice(index, 1);
    this.dialogues.next(dialogs);
  }

  showPaymentMethodReminderDialog() {
    let content =
      this.translateService.instant('Dialog.PaymentMethodMissing.Content') +
      ' ' +
      this.translateService.instant('Dialog.GenericBanWarning');
    if (environment.featureFlags.enableContractCreation) {
      content += '<br/>' + this.translateService.instant('Dialog.NoContractsWithoutPaymentMethodWarning');
    }

    this.addDialog({
      title: this.translateService.instant('Dialog.PaymentMethodMissing.Title'),
      subtitle: this.translateService.instant('Dialog.PaymentMethodMissing.Subtitle'),
      content,
      dismissible: true,
      buttonText: this.translateService.instant('Dialog.PaymentMethodMissing.ButtonText'),
      buttonRoute: { link: '/billing', queryParams: { showPaymentForm: 'true' } },
      type: 'error',
    });
  }

  showCustomerAddressReminderDialog() {
    let content =
      this.translateService.instant('Dialog.CustomerAddressMissing.Content') +
      ' ' +
      this.translateService.instant('Dialog.GenericBanWarning');
    if (environment.featureFlags.enableContractCreation) {
      content += '<br/>' + this.translateService.instant('Dialog.NoContractsWithoutPaymentMethodWarning');
    }

    this.addDialog({
      title: this.translateService.instant('Dialog.CustomerAddressMissing.Title'),
      subtitle: this.translateService.instant('Dialog.CustomerAddressMissing.Subtitle'),
      content: content,
      dismissible: true,
      buttonText: this.translateService.instant('Dialog.CustomerAddressMissing.ButtonText'),
      buttonRoute: { link: '/data' },
      type: 'error',
    });
  }

  showFinishRegistrationReminderDialog() {
    let content =
      this.translateService.instant('Dialog.PaymentMethodAndCustomerAddressMissing.Content') +
      ' ' +
      this.translateService.instant('Dialog.GenericBanWarning');
    if (environment.featureFlags.enableContractCreation) {
      content += '<br/>' + this.translateService.instant('Dialog.NoContractsWithoutPaymentMethodWarning');
    }

    this.addDialog({
      title: this.translateService.instant('Dialog.PaymentMethodAndCustomerAddressMissing.Title'),
      subtitle: this.translateService.instant('Dialog.PaymentMethodAndCustomerAddressMissing.Subtitle'),
      content,
      dismissible: true,
      buttonText: this.translateService.instant('Dialog.PaymentMethodAndCustomerAddressMissing.ButtonText'),
      buttonRoute: { link: '/onboarding/finish' },
      type: 'error',
    });
  }
}
