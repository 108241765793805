<app-arivo-toast></app-arivo-toast>
<router-outlet />
<div *ngIf="loading" class="fixed inset-0 flex items-center justify-center backdrop-blur cursor-wait z-200">
  <mat-spinner></mat-spinner>
</div>

@if (showCookieDialog) {
  <div class="cookie-dialog-container">
    <div class="card card-small">
      <div class="card-body">
        <div class="body-medium mb-1">We use cookies and similar technologies to improve and enhance your user experience.</div>

        <div class="body-medium mb-1">
          Strictly necessary cookies are required for the app to function and cannot be disabled. Optional cookies are used to monitor the
          performance of the app and gather error reports.
        </div>

        <div class="flex flex-wrap justify-end gap-2 mt-3">
          <button type="button" class="button button-primary-text button-small" (click)="rejectNonEssentialCookies()">Reject all</button>
          <button type="submit" class="button button-primary button-small" (click)="acceptAllCookies()">Accept cookies</button>
        </div>
      </div>
    </div>
  </div>
}
