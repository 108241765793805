import { Component, Input } from '@angular/core';
import { OnboardingService } from '../onboarding.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ArivoStepperComponent } from '../../../../shared/components/ui-components/arivo-stepper/arivo-stepper.component';
import { LicensePlate } from '../../../../shared/models/licensePlate';
import { fbOptionalString } from '../../../../shared/utils/form.utils';
import { ToastService } from '../../../../shared/services/toast.service';
import { showSpinner } from '../../../../shared/utils/common.utils';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-onboarding-third-step',
  templateUrl: './onboarding-third-step.component.html',
  styleUrl: './onboarding-third-step.component.scss',
})
export class OnboardingThirdStepComponent {
  @Input() stepperComponent?: ArivoStepperComponent;

  emailSent: boolean = false;
  email?: string;
  marketingConsentEnabled: boolean = environment.featureFlags.enableMarketingConsent;

  constructor(
    private _formBuilder: FormBuilder,
    private translateService: TranslateService,
    private onboardingService: OnboardingService,
    private toastService: ToastService,
  ) {}

  get licensePlate(): LicensePlate | null {
    return this.onboardingService.licensePlate.getValue();
  }

  signUpForm = this._formBuilder.group({
    email: [fbOptionalString, [Validators.required, Validators.email]],
    termsAndConditions: [false, [Validators.requiredTrue]],
    marketingConsent: [false],
  });

  previous() {
    if (this.stepperComponent) {
      this.stepperComponent.selectedIndex = 0;
    }
  }

  next() {
    this.signUpForm.markAllAsTouched();
    if (this.signUpForm.valid && this.licensePlate) {
      this.onboardingService
        .sendOnboardingEmail(this.licensePlate, this.signUpForm.value.email!, this.signUpForm.value.marketingConsent!)
        .pipe(showSpinner())
        .subscribe({
          next: () => {
            this.emailSent = true;
            this.email = this.signUpForm.value.email!;

            this.toastService.add({
              message: this.translateService.instant('LogIn.WeHaveSentAnEmailToast', { email: this.signUpForm.value.email! }),
              type: 'success',
            });
          },
          error: (error) => {
            if (error.error.detail) {
              const detail = error.error.detail;
              for (const key in detail) {
                if (detail.hasOwnProperty(key)) {
                  detail[key].forEach((message: string) => {
                    this.toastService.add({
                      message: message,
                      type: 'error',
                    });
                  });
                }
              }
            } else {
              this.toastService.add({
                message: this.translateService.instant('LogIn.CouldNotSendEmail'),
                type: 'error',
              });
            }
          },
        });
    }
  }
}
