import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { ArivoStepComponent, StepState } from './arivo-step/arivo-step.component';

@Component({
  selector: 'arivo-stepper',
  templateUrl: './arivo-stepper.component.html',
  styleUrl: './arivo-stepper.component.scss',
})
export class ArivoStepperComponent implements AfterContentInit {
  @ContentChildren(ArivoStepComponent) steps?: QueryList<ArivoStepComponent>;

  @Input() showHeader: boolean = true;

  ngAfterContentInit() {
    this.selectedIndex = this._currentIndex;
  }

  @Input()
  get selectedIndex(): number {
    return this._currentIndex;
  }

  set selectedIndex(index: number) {
    this._currentIndex = index;
    if (this.steps) {
      this.steps.forEach((step, i) => {
        if (i < index) {
          step.state = StepState.done;
        } else if (i === index) {
          step.state = StepState.active;
        } else {
          step.state = StepState.number;
        }
      });
    }
  }
  private _currentIndex: number = 0;

  previous(): void {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    }
  }

  next(): void {
    if (this.steps && this.selectedIndex < this.steps.length - 1) {
      this.selectedIndex++;
    }
  }
}
